import logo from './logo.svg';
import './App.css';
import Navbar from "./components/Navbar";
import QRGenerator from "./components/QRGenerator";
import {ChakraProvider} from "@chakra-ui/react";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Home from "./components/Home";
import UnderConstruction from "./components/UnderConstruction";
import SignUp from "./components/SignUp";
import ErrorBoundary from "./components/ErrorBoundary";
function App() {
    return (
        <ChakraProvider>
            <Router>
                <div className="App">
                    <Navbar/>
                    <header className="App-header">
                        <ErrorBoundary>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/qrgenerator" element={<QRGenerator />} />
                                <Route path="/under-construction" element={<UnderConstruction />} />
                                <Route path={"/signup"} element={<SignUp/>} />
                            </Routes>
                        </ErrorBoundary>
                    </header>
                </div>
            </Router>
        </ChakraProvider>
    );
}

export default App;
