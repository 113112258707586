// src/components/QRGenerator.js
import React from 'react';
import Form from "./Form";
import axiosInstance from "../axiosInstance";
const QRGenerator = () => {

    const [imageUrl, setImageUrl] = React.useState("");
    const getQRCode = (formData) => {
        console.log("props: ",formData);
        if (formData.qr_text === ""){
            alert("Please enter the text for QR Code"); return;
        }
        var result = axiosInstance.post("/qr/generate/",
            formData);
        result.then((response) => {
            setImageUrl(response.data.url);
        })
    }
    const downloadQRCode = () => {
        if(imageUrl !== ""){
            window.open(imageUrl, '_blank');
        }
    }

    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2  gap-4">
                <div className="p-4">
                    <Form getQRCode = {getQRCode}
                          downloadQRCode = {downloadQRCode}/>
                </div>
                <div id={"qr-preview"} className="p-4 flex justify-center items-center">
                    {imageUrl !== "" ? <img src={imageUrl} alt="QR Preview" className="w-96 h-96" /> : ""}

                </div>
            </div>
        </div>
    );
};

export default QRGenerator;