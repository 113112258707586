// src/components/Signup.js
import React, { useState } from 'react';
import {Box, Button, FormControl, FormLabel, Input, useToast, VStack} from '@chakra-ui/react';
import axiosInstance from "../axiosInstance";
import {useNavigate} from "react-router-dom";


function SignUp() {
    const [formData, setFormData] = useState({
        first_name :"",
        last_name : "",
        middle_name:"",
        user_name : "",
        password : "",
        email: "",
        gender: "",
        phone_number: ""
    });
    const navigate = useNavigate();
    const toast = useToast();
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [middle_name, setMiddleName] = useState("");
    const [user_name, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [phone_number, setPhoneNumber] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setFirstName(e.target.first_name)
        setLastName(e.target.last_name)
        setMiddleName(e.target.middle_name)
        setUserName(e.target.user_name)
        setPassword(e.target.password)
        setEmail(e.target.email)
        setGender(e.target.gender)
    };

    const resetFormData = () => {
        setFirstName("");
        setLastName("");
        setMiddleName("");
        setUserName("");
        setPassword("");
        setEmail("");
        setGender("");
        setPhoneNumber("");
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {firstName, lastName, middleName, email, username, gender,password, phone_number} = formData;

        try {
            var result = await axiosInstance.post('/auth/signup', {
                first_name: firstName,
                last_name: lastName,
                middle_name: middleName,
                gender: gender,
                email: email,
                user_name: username,
                password: password,
                phone_number: phone_number
            });
            resetFormData()
            console.log('Sign up successful');
            navigate('/')
        } catch (error) {
            console.error('Error signing up: ', error);
            toast({
                title: "Signup failed",
                description: error.response?.data?.detail || "An error occurred during signup.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        console.log(formData);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <VStack as="form" onSubmit={handleSubmit} spacing={4} width="300px">
                <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input name="firstName" value={formData.firstName} onChange={handleChange} />
                </FormControl>
                <FormControl id="middleName" isRequired>
                    <FormLabel>Middle Name</FormLabel>
                    <Input name="middleName" value={formData.middleName} onChange={handleChange} required={false}/>
                </FormControl>
                <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input name="lastName" value={formData.lastName} onChange={handleChange} />
                </FormControl>
                <FormControl id="email" isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" name="email" value={formData.email} onChange={handleChange} />
                </FormControl>
                <FormControl id="gender" isRequired>
                    <FormLabel>Gender</FormLabel>
                    <Input name="gender" value={formData.gender} onChange={handleChange} />
                </FormControl>
                <FormControl id="username" isRequired>
                    <FormLabel>Preferred Username</FormLabel>
                    <Input name="username" value={formData.username} onChange={handleChange} />
                </FormControl>
                <FormControl id="phone_number" isRequired>
                    <FormLabel>Phone Number</FormLabel>
                    <Input name="phone_number" value={formData.phone_number} onChange={handleChange} />
                </FormControl>
                <FormControl id="password" isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" name="password" value={formData.password} onChange={handleChange} />
                </FormControl>
                <Button type="submit" colorScheme="teal" width="full">Sign Up</Button>
            </VStack>
        </Box>
    );
}

export default SignUp;