// src/components/Navbar.js
import React from 'react';

const Navbar = () => {
    return (
        <nav className="bg-gray-800 p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-white text-lg font-bold">BCQR</div>
                <div className="flex-1 flex justify-center space-x-4">
                    <a href="/" className="text-gray-300 hover:text-white">Home</a>
                    <a href="/qrgenerator" className="text-gray-300 hover:text-white">QR Generator</a>
                    <a href="/under-construction" className="text-gray-300 hover:text-white">Contact</a>
                    <a href="/signup" className="text-gray-300 hover:text-white">Sign Up</a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;